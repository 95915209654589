import React from "react";

import { Link } from "react-router-dom";

const GoToHomepage = () => {
  return (
    <>
      <Link to="/">
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7129 43.4268L1.21289 22.7139L21.7129 1.00035"
            stroke="#F5F3F0"
          />
          <line
            y1="-0.5"
            x2="42.4264"
            y2="-0.5"
            transform="matrix(1 -8.42937e-08 -8.42937e-08 -1 1 22.2139)"
            stroke="#F5F3F0"
          />
        </svg>

        <p className="font-inter text-xl text-white mt-4">Home</p>
      </Link>
    </>
  );
};

export default GoToHomepage;
