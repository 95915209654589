import React, { useState } from "react";
import Navbar from "./Navbar";
import SideNav from "./sideNav";
import bg from "../assets/images/Grid_background.png";
import { useLocation } from "react-router-dom";

const Body = ({ children, pageRef }) => {
  const [navbar, setNavbar] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <div
        className="bg-black min-h-[100vh] h-[100%] sm:bg-center bg-center bg-cover	bg-no-repeat"
        style={
          ["/", "/about"].includes(pathname)
            ? {
                backgroundImage: `url(${bg})`,
              }
            : {}
        }
      >
        <div className="h-[100%] md:flex items-start justify-between relative">
          <div className="h-[90%]  md:mx-auto w-full ">
            <Navbar {...{ navbar, setNavbar, pageRef }} />
            {!navbar && children}
          </div>
          <SideNav {...{ navbar, setNavbar }} />
        </div>
      </div>
    </>
  );
};

export default Body;
