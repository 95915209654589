import React from "react";
import { PrimaryButton } from "../components/ModButtons";
import bg from "../assets/images/Landing_Graphic.png";
import { motion } from "framer-motion";

const Homepage = ({ pageRef }) => {
  const scrollToFooter = () => {
    pageRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <motion.div
      className="min-h-[90vh] relative"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-wrap flex-col justify-between items-start md:mx-auto h-full px-8 md:px-16 z-10 relative">
        <div className="w-[100%] md:w-[75%] my-8">
          <h1 className="text-[40px] sm:text-headline font-light text-white leading-tight font-sans break-words">
            The world's fastest <br />
            fully homomorphic encryption <br />
            is here.
          </h1>
          <h1 className="text-[20px] sm:text-subHeadline  text-white my-8 font-inter font-light ">
            Process encrypted data at scale. Finally.
          </h1>
          <PrimaryButton onClick={scrollToFooter}>Learn More</PrimaryButton>
        </div>
      </div>
      <div
        className="bg-contain	bg-no-repeat absolute bottom-[100px] md:top-0 left-0  w-full h-full bg-bottom md:bg-center z-1 "
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "85%",
          backgroundPositionX: "75%",
        }}
      ></div>
    </motion.div>
  );
};

export default Homepage;