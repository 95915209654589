import React from "react";
import { PrimaryButton } from "../components/ModButtons";
import bg from "../assets/images/Viz.png";
import { motion } from "framer-motion";
import GoToHomepage from "../components/GoToHomepage";

const UseCases = ({ pageRef }) => {
  
  const scrollToFooter = () => {
    pageRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <motion.div
      className="md:bg-right-bottom md:bg-[length:650px] bg-bottom bg-contain bg-no-repeat h-[100%] flex flex-col px-8 md:px-16  font-inter"
      style={{
        backgroundImage: `url(${bg})`,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <p className="text-[40px] sm:text-headline block sm:hidden my-4 text-white text-left mr-auto  font-sans">
        Use Cases
      </p>
      <div className="grid md:grid-cols-3 grid-cols-1 my-0 sm:my-12  mx-auto gap-16 h-content justify-between pb-20">
        <div className="bg-black border border-red flex flex-col justify-between items-start p-6 relative">
          <div>
            <span className="text-2xl md:text-subHeadline text-white mb-10">
            Encrypted Financial Exchanges
            </span>
            <p className=" text-white mb-16 mt-10 ">
            Plaintext trade information can make financial exchanges risky for investors (front-running, information leakages, conflict of interests). Thanks to its unique low latency capabilities, RHE resolves trust and confidentiality issues to improve costs and trade executions’ efficiency for financial institutions and corporates where needed most. Moreover, at a time where traditional finance usage of blockchain infrastructures is arising, RHE radically preserves market integrity by preventing any potential collusion risks created by blockchain’s transparency.
            </p>
          </div>
         
          <div className="connector-line"></div>
        </div>

        <div className="bg-black border border-red flex flex-col justify-between items-start p-6 relative">
          <div>
            <span className="text-2xl md:text-subHeadline text-white ">
            Privacy-preserving deidentified targeted advertising
            </span>
            <p className=" text-white mb-16 mt-10">
            Thanks to the power of RHE, we can offer unparalleled user privacy protection & regulatory immunity (e.g. GDPR, CCPA) to advertisers, publishers and adtech companies. Our unrivaled plug-and-play API-based solution keeps data de-identified and irreversibly encrypted from collection and onwards to processing & targeting operations.
            </p>
          </div>
          
          <div className="connector"></div>
          <div className="connector-line"></div>
        </div>

        <div className="bg-black border border-red flex flex-col justify-between items-start p-6 relative">
          <div>
            <span className="text-2xl md:text-subHeadline text-white mb-10">
            Large Scale Confidential Data Sharing & Analytics
            </span>
            <p className=" text-white mb-16 mt-10">
            What if health agencies could share millions of patients' data to help science progress, banks swiftly run KYC checks by querying each other and credit agencies or insurance companies benefit from larger data sets to gain efficiency?
            RHE makes it possible to efficiently provide intra- and inter-organization data-sharing on billions of data points while respecting confidentiality and privacy.

            </p>
          </div>
         
          <div className="connector"></div>
          
        </div>
        <div> <PrimaryButton className="w-content" onClick={scrollToFooter}>Learn More</PrimaryButton> </div>
       
        
      </div>
      <div className="md:block hidden mt-6 mb-20 ">
        <GoToHomepage />
      </div>
    </motion.div>
    
  );
};

export default UseCases;