import React from "react";
import { PrimaryButton } from "../components/ModButtons";
import user1 from "../assets/images/user1.jpg";
import user2 from "../assets/images/user2.jpg";
// import user3 from "../assets/images/user3.png";
import user4 from "../assets/images/user4.jpg";
import user5 from "../assets/images/user5.jpg";
import { BsLinkedin } from "react-icons/bs";
import { motion } from "framer-motion";
import GoToHomepage from "../components/GoToHomepage";

const members = [
  {
    name: "Mehdi Sabeg",
    title: "Chief Executive Officer",
    image: user1,
    description:
      "Masters in Fundamental Physics, Sorbonne University Masters in Energy Economics, IFP School",
    linkedIn: "https://www.linkedin.com/in/mehdi-sabeg-34babab0/",
  },
  {
    name: "Dr. Michel Koskas",
    title: "Chief Scientific & Technical Officer",
    image: user2,
    description:
      "PhD in Mathematics, École Normale Supérieure. Led Major Scientific Breakthroughs in Database & Genomics ",
    linkedIn: "https://www.linkedin.com/in/michel-koskas-a69633247/",
  },
  // {
  //   name: "Richard Rodrigues",
  //   title: "Chief Product Officer",
  //   image: user3,
  //   description: "20 Years Experience in Tech  (Havas, Weborama, Cap Gemini)",
  //   linkedIn: "https://www.linkedin.com/in/richardrodrigues/",
  // },
  {
    name: "Jean-Louis Gergorin",
    title: "Strategy Counsel",
    image: user4,
    description: "Former Executive Vice President (Strategy) at EADS (now Airbus)",
    linkedIn: "https://www.linkedin.com/in/jean-louis-gergorin-759b708/",
  },
  {
    name: "Dr. Cédric Villani",
    title: "Scientific Advisor",
    image: user5,
    description:
      "Fields Medal Awardee, PhD in Mathematics, École Normale Supérieure ",
    linkedIn: "https://www.linkedin.com/in/c%C3%A9dric-villani/",
  },
];

const Team = ({ pageRef }) => {
  const scrollToFooter = (e) => {
    e.preventDefault();
    pageRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="h-full md:bg-center bg-bottom bg-contain bg-no-repeat mt-12 sm:mt-0 flex flex-col justify-between pb-16 md:pb-0 font-inter"
    >
      <div className="flex flex-wrap flex-col justify-between items-start md:mx-auto px-8 md:px-16 ">
        <div className="w-[100%] sm:w-[60%] md:w-[50%] mb-4 ">
          <h1 className="text-body  text-white">
          Ravel is a growing team of over 15 people including talented software engineers from the top universities in France and senior distinguished researchers in mathematics from prestigious research institutes.
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:mx-auto gap-8 w-full mt-8 md:mt-0">
          {members.map((item, i) => (
            <div
              className="border border-red  flex flex-col lg:flex-row justify-start items-start p-4 text-white gap-8 px-12 mb-8"
              key={i}
            >
              <img
                src={item.image}
                alt=""
                className="h-40 w-40 shrink-0 mr-8"
              />
              <div className="mt-6 lg:mt-0">
                <h1 className="text-xl font-bold">{item.name}</h1>
                <p className="">{item.title}</p>
                <p className=" text-white my-4 ">{item.description}</p>
                <a href={item?.linkedIn} target="_blank" rel="noreferrer">
                  <BsLinkedin className="text-3xl" />
                </a>
              </div>
            </div>
          ))}
          <div className="hidden md:flex flex-col  items-start justify-center p-6 text-white px-0">
            <div className="w-full sm:w-[80%] mx-auto">
              <h1 className="text-3xl font-sans mb-8">
              Interested to be part of the team?
              </h1>
              <PrimaryButton onClick={scrollToFooter}>Join Us</PrimaryButton>
            </div>
          </div>
        </div>

        <div className="flex md:hidden flex-col  items-start justify-center p-6 text-white px-0">
          <div className="w-full mx-auto">
            <h1 className="text-3xl font-sans mb-8">
            Interested to be part of the team?
            </h1>
            <PrimaryButton onClick={scrollToFooter}>Join Us</PrimaryButton>
          </div>
        </div>

        <div className="md:block hidden mt-6 mb-20">
          <GoToHomepage />
        </div>
      </div>
    </motion.div>
  );
};

export default Team;