import React from "react";

export const PrimaryButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={`bg-white px-10 py-2.5 text-md text-black font-semibold font-inter font-700 border border-red-700 hover:bg-[#DFDFDF] ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export const SecondaryButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={`bg-black px-10 py-2.5 text-md text-white font-semibold border border-black hover:bg-[#424242] ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
export const SecondaryAltButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={`bg-black px-10 py-2.5 text-md text-white font-semibold border border-white hover:bg-[#424242] ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
export const SuccessButton = ({ children, className, ...rest }) => {
  return (
    <button
      className={`bg-[#CBF3B7] px-10 py-2.5 text-md text-black font-semibold border  border-black border-red-700 hover:bg-[#beeca7] ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
