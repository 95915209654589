import React, { useState } from 'react'
import { SecondaryButton, SuccessButton } from './ModButtons'
import { forwardRef } from 'react'
import clsx from 'clsx'

const submissionReceivers = [
  'raveltechcareers@gmail.com',
  'salesinquiriesraveltech@gmail.com',
]

const Footer = forwardRef((props, ref) => {
  const returnToTop = () => {
    window.scrollTo(0, 0)
  }
  const [success, setSuccess] = useState(false)
  const initialState = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    message: '',
  }

  const [formData, setFormData] = useState(initialState)
  const [error, setError] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let q = checkValidation(formData)
    setError(q)
    if (Object.keys(q).length === 0) {
      submissionReceivers.map((email) => {
        let request = new XMLHttpRequest()

        request.onreadystatechange = function () {
          if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
              console.log(request.responseText)
            } else {
              console.log(request.responseText)
            }
          }
        }

        request.open('POST', 'https://api.notivize.com/events', true)
        request.setRequestHeader('Content-Type', 'application/json')
        request.setRequestHeader(
          'Authorization',
          'Bearer f181a8e3ff4c55ddb2d5.348c82c1-76c0-4cf1-be77-34156d5ffa16',
        )
        request.send(
          JSON.stringify({
            event_flow_id: '17638eab-0a4f-468b-9b9b-f87e6358e9b6',
            variables: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              message: formData.message,
              submittersEmail: formData.email,
            },
            to: {
              email,
            },
          }),
        )
      })

      setTimeout(() => {
        setSuccess(true)
        setFormData(initialState)
      }, 2000)
    }
  }

  const checkValidation = (data) => {
    const errors = {}
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (!data.email) {
      errors.email = 'Must Complete Form'
    }
    if (data?.email && !data.email.match(mailformat)) {
      errors.email = 'Invalid Email Address'
    }

    if (!data.message) {
      errors.message = 'Must Complete Form'
    }
    if (!data.firstName) {
      errors.firstName = 'Must Complete Form'
    }
    if (!data.lastName) {
      errors.lastName = 'Must Complete Form'
    }
    if (!data.company) {
      errors.company = 'Must Complete Form'
    }

    return errors
  }

  return (
    <div className="bg-white py-12 h-full font-inter " id="footer" ref={ref}>
      <div className="px-8 md:px-16 flex flex-wrap flex-col justify-between items-start">
        <div className="flex flex-col justify-between items-center py-8 lg:px-0 w-full mx-auto">
          {/* <div className="w-full"> */}
          <div className="text-[40px] sm:text-headline font-sans leading-none	mb-4 w-full">
            Let's stay in touch.
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-12 md:gap-52 justify-between">
            <div className="col-span-1 w-full">
              <div className="flex flex-col justify-between h-full">
                <div className="details font-inter">
                  <p className="font-medium  text-body m-0 ">General</p>
                  <h1 className=" text-body">inquiries@raveltech.io</h1>
                  <p className="font-medium text-body mt-4">Join us </p>
                  <h1 className="text-body">careers@raveltech.io</h1>
                  <p className="font-medium text-body mt-4 ">Location</p>
                  <h1 className="text-body">75 Rue de Richelieu </h1>
                  <h1 className="text-body">75002 Paris</h1>
                </div>
                <div className="return hidden sm:block w-full">
                  <div className="return hidden sm:block">
                    <span onClick={returnToTop} className="cursor-pointer">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block text-start"
                      >
                        <path
                          d="M1 21.7129L21.7129 1.21289L43.4264 21.7129"
                          stroke="#202020"
                        />
                        <line
                          y1="-0.5"
                          x2="42.4264"
                          y2="-0.5"
                          transform="matrix(0 1 1 0 22.2129 1)"
                          stroke="#202020"
                        />
                      </svg>
                      <br />
                      <p className="text-xl font-inter mt-4 font-light w-fit inline-block ">
                        Return to top
                      </p>
                    </span>
                  </div>
                  <div className="w-full tooltip mt-4 text-ascentGray font-[8px]">
                    Legal and Copyright Terms
                    <span className="tooltiptext">
                      <p>RAVEL TECHNOLOGIES SAS</p>
                      <p>75 rue de Richelieu 75002 PARIS</p>
                      <p>RCS Paris 844 443 127</p>
                      <p>N° de TVA intracommunautaire:FR84844443127</p>
                      
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1 w-full sm-mt-0">
              <p className="font-semibold text-body mb-1">Sales inquiries</p>
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      id="floating_first_name"
                      placeholder="First Name"
                      onChange={handleInputChange}
                      className={clsx(
                        'bg-white py-2.5 px-0   w-full  active:border-0 focus:border-none  border-0 border-b',
                        error.firstName
                          ? 'border-b-altRed focus:border-altRed placeholder-altRed text-altRed'
                          : '',
                      )}
                    />
                    {error.firstName && (
                      <p className="italic text-sm text-altRed font-medium">
                        Must Complete Form
                      </p>
                    )}
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      id="floating_first_name"
                      placeholder="Last Name"
                      onChange={handleInputChange}
                      className={clsx(
                        'bg-white py-2.5 px-0   w-full  active:border-0 focus:border-none  border-0 border-b',
                        error.lastName
                          ? 'border-b-altRed focus:border-altRed placeholder-altRed text-altRed'
                          : '',
                      )}
                    />{' '}
                    {error.lastName && (
                      <p className="italic text-sm text-altRed font-semibold">
                        Must Complete Form
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      id="floating_first_name"
                      placeholder="Company"
                      onChange={handleInputChange}
                      className={clsx(
                        'bg-white py-2.5 px-0   w-full  active:border-0 focus:border-none  border-0 border-b',
                        error.company
                          ? 'border-b-altRed focus:border-altRed placeholder-altRed text-altRed'
                          : '',
                      )}
                    />{' '}
                    {error.company && (
                      <p className="italic text-sm text-altRed font-semibold">
                        Must Complete Form
                      </p>
                    )}
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      id="floating_first_name"
                      placeholder="Email"
                      onChange={handleInputChange}
                      className={clsx(
                        'bg-white py-2.5 px-0   w-full  active:border-0 focus:border-none  border-0 border-b',
                        error.email
                          ? 'border-b-altRed focus:border-altRed placeholder-altRed text-altRed'
                          : '',
                      )}
                    />
                    {error.email && (
                      <p className="italic text-sm text-altRed font-semibold">
                        Invalid Email Address
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid md:grid-cols-1 md:gap-6">
                  <div className="relative z-0 mb-4 w-full group">
                    <label
                      htmlFor="message"
                      className={clsx(
                        ' text-md font-medium',
                        error.message ? 'text-altRed' : '',
                      )}
                    >
                      Message
                    </label>
                    <textarea
                      type="text"
                      name="message"
                      value={formData.message}
                      id="floating_first_name"
                      placeholder="Write your message here."
                      onChange={handleInputChange}
                      rows={5}
                      className={clsx(
                        'bg-white py-2.5 px-2 mt-2  w-full  active:border-0 focus:border-none active:outline-0	  border',
                        error.message
                          ? 'border-altRed focus:border-altRed placeholder-altRed text-altRed'
                          : '',
                      )}
                    />
                    {error.message && (
                      <p className="italic text-sm text-altRed font-semibold">
                        Must Complete Form
                      </p>
                    )}
                  </div>
                </div>
                <p className="text-left italic text-sm mb-2">
                  (By completing this form you agree to be contacted by Ravel Technologies)
                </p>
                {success ? (
                  <SuccessButton>Message Sent</SuccessButton>
                ) : (
                  <SecondaryButton type="submit">Send</SecondaryButton>
                )}
              </form>
              <div className="return block sm:hidden mt-12 sm w-full">
                <div className="return block sm:hidden mt-12 sm">
                  <span onClick={returnToTop} className="cursor-pointer">
                    <svg
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline-block text-start"
                    >
                      <path
                        d="M1 21.7129L21.7129 1.21289L43.4264 21.7129"
                        stroke="#202020"
                      />
                      <line
                        y1="-0.5"
                        x2="42.4264"
                        y2="-0.5"
                        transform="matrix(0 1 1 0 22.2129 1)"
                        stroke="#202020"
                      />
                    </svg>
                    <br />
                    <p className="text-xl font-inter my-4 font-light w-fit inline-block ">
                      Return to top
                    </p>
                  </span>
                </div>
                <div className="w-full tooltip text-ascentGray font-[8px]">
                  Legal and Copyright Terms
                  <span className="tooltiptext">
                    <p>RAVEL TECHNOLOGIES SAS</p>
                    <p>75 rue de Richelieu 75002 PARIS</p>
                    <p>RCS Paris 844 443 127</p>
                    <p>N° de TVA intracommunautaire:FR84844443127</p>
                      
                    
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  )
})

export default Footer
