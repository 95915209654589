import React from "react";
import bg from "../assets/images/Viz2.png";
import { motion } from "framer-motion";
import GoToHomepage from "../components/GoToHomepage";

const About = () => {
  
  return (
    <motion.div
      className="md:bg-right bg-bottom bg-contain	bg-no-repeat  min-h-[90vh] flex flex-col justify-between font-inter"
      style={{
        backgroundImage: `url(${bg})`,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-wrap flex-col justify-between items-start md:mx-auto px-8 md:px-16">
        <div className="w-[80%] md:w-[50%] my-8">
          <h1 className="text-[40px] sm:text-headline text-white leading-tight font-sans font-light">
            Mathematical breakthroughs.
          </h1>
          <h1 className="text-[20px] md:text-subHeadline  text-white my-8 mt-12 font-light">
            We've created the next generation of fully homomorphic encryption building on our unique mathematical and algorithmic breakthroughs.
          </h1>
          <h1 className="text-[20px] md:text-subHeadline  text-white my-8 font-light">
            We leverage RHE extraordinary capabilities to radically improve
            businesses efficiency by eliminating privacy and confidentiality
            issues from the equation. We are building singular solutions. Check out our{" "}
            <span className="underline">
              <a href="/usecases">Use Cases</a>
            </span>.
          </h1>
        </div>
      </div>
      <div className="md:block hidden pb-24 px-8 md:px-16 mt-6 mb-20">
        <GoToHomepage />
      </div>
    </motion.div>
  );
};

export default About;
