

import React, { useEffect, useState } from "react";
import { PrimaryButton, SecondaryAltButton } from "./ModButtons";
import Logo from "../assets/images/ravelLogo.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ navbar, setNavbar, pageRef }) => {
  const [scroll, setScroll] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (scroll && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
      setScroll(false);
    }
  }, [scroll]);

  const scrollToFooter = (e) => {
    e.preventDefault();
    setScroll(true);
  };

  return (
    <>
      <nav
        className={`border-gray-200  py-6 rounded dark:bg-gray-900 bg-primary px-8 md:px-16 ${
          navbar ? "bg-black" : ""
        } z-10 relative`}
      >
        <div className="flex flex-wrap justify-between items-center md:mx-auto">
          <Link
            to="/"
            className="flex items-center"
            onClick={() => setNavbar(false)}
          >
            <img
              src={Logo}
              className="mr-3 w-[90px] h-auto"
              alt="Flowbite Logo"
            />
          </Link>

          {navbar ? (
            <PrimaryButton
              onClick={() => setNavbar(!navbar)}
              className="md:hidden"
            >
              Close
            </PrimaryButton>
          ) : (
            <SecondaryAltButton
              onClick={() => setNavbar(!navbar)}
              className="md:hidden "
            >
              Menu
            </SecondaryAltButton>
          )}

          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <span
                  onClick={scrollToFooter}
                  // id="app-download"
                  className="block font-inter font-400 py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  <SecondaryAltButton>Contact Us</SecondaryAltButton>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        className={`w-full font-inter h-screen flex justify-self-center pb-3 md:hidden md:pb-0 md:mt-0 ${
          navbar ? "block bg-black" : "hidden"
        }`}
      >
        <ul className="flex flex-col mt-4 md:flex-row  md:mt-0 md:text-sm md:font-medium h-full w-full">
          <li
            className={` border-white border-b border-t ${
              pathname === "/" && "bg-altGray"
            } mobile-nav-hover`}
          >
            <Link
              to="/"
              className="block h-full py-8  pr-4 ml-auto pl-3 mt-auto text-white text-2xl"
              aria-current="page"
              onClick={() => setNavbar(!navbar)}
            >
              Home
            </Link>
          </li>
          <li
            className={` border-white border-b ${
              pathname === "/about" && "bg-altGray"
            } mobile-nav-hover`}
          >
            <Link
              to="/about"
              className="block h-full py-8  pr-4 ml-auto pl-3 mt-auto text-white text-2xl"
              aria-current="page"
              onClick={() => setNavbar(!navbar)}
            >
              About
            </Link>
          </li>
          <li
            className={` border-white border-b ${
              pathname === "/technologies" && "bg-altGray"
            } mobile-nav-hover`}
          >
            <Link
              to="/technologies"
              className="block h-full py-8  pr-4 ml-auto pl-3 mt-auto text-white text-2xl "
              onClick={() => setNavbar(!navbar)}
            >
              Technologies
            </Link>
          </li>
          <li
            className={` border-white border-b  ${
              pathname === "/usecases" && "bg-altGray"
            } mobile-nav-hover`}
          >
            <Link
              to="/usecases"
              className="block h-full py-8  pr-4 ml-auto pl-3 mt-auto text-white text-2xl"
              onClick={() => setNavbar(!navbar)}
            >
              Use Cases
            </Link>
          </li>
          <li
            className={` border-white border-b ${
              pathname === "/team" && "bg-altGray"
            } mobile-nav-hover`}
          >
            <Link
              to="/team"
              className="block h-full py-8  pr-4 ml-auto pl-3 mt-auto text-white text-2xl"
              onClick={() => setNavbar(!navbar)}
            >
              Team
            </Link>
          </li>
          <li className="border-white border-b mobile-nav-hover">
            <span
              className="block h-full py-8  pr-4 ml-auto pl-3 mt-auto text-white text-2xl cursor-pointer"
              onClick={(event) => {
                setNavbar(!navbar);
                scrollToFooter(event);
              }}
            >
              Contact
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;