import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Body from "./components/Body";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedRoutes from "./components/AnimatedRoutes";
import Footer from "./components/Footer";
import { useRef } from "react";

function App() {
  const pageRef = useRef(null);
  const offline = process.env.REACT_APP_OFFLINE || "false";

  if (offline === "true") {
    return <div>Website is under maintenance</div>;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Body pageRef={pageRef}>
          <AnimatedRoutes pageRef={pageRef} />
        </Body>
        <Footer ref={pageRef} />
      </BrowserRouter>
    </>
  );
}

export default App;