import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import GoToHomepage from "../components/GoToHomepage";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { PrimaryButton } from "../components/ModButtons";
import video1 from "../assets/images/image1.gif";
import video2 from "../assets/images/image2.gif";
import video3 from "../assets/images/image3.gif";
import CloseSVG from "../assets/svg/Close.svg";
import performanceImg from "../assets/images/performance.jpg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const contents = [
  {
    title:
      "Nearly 4 orders of magnitude faster than state of the art. Only 30x larger than plaintext data.",
    content:
      "RHE is a singular patents-pending fully homomorphic encryption scheme that smashes performance barriers previously limiting other state-of-the-art HE libraries. We've made quantum leaps through groundbreaking algorithmic improvements, and our tech runs on CPUs.",
    video: video1,
    button: "See how we compare",
  },
  {
    title: "Fully encrypted SQL database.",
    content:
      "RHE powers the first fully encrypted SQL database, enabling queries over billions of encrypted data points.",
    video: video2,
    // button: "Learn about the discovery",
  },
  {
    title: "Fully encrypted machine learning platform.",
    content:
      "Fast encrypted machine learning, using RHE capacities to efficiently generate encrypted predictions.",
    video: video3,
    // button: "Read more",
  },
];

const showModalContent = {
  content: (
    <>
      <div className="text-justify">
        <br />
        <p className="text-sm">
          Fully homomorphic encryption libraries are divided in three families,
          each used by our competitors: TFHE, BGV/BFV and CKKS. All those
          libraries suffer from show ending performances and lack fundamental
          functionalities in order to be used in industrial use cases, such as
          the ability to (1) efficiently run over large encrypted integers with
          (2) a satisfying level of error probability for (3) a variety of
          complex functions.
        </p>
        <br />
        <p className="text-sm">
          Indeed, homomorphic encryption has to be efficient enough to power
          database and machine learning applications over mainstream CPU-based
          cloud infrastructure. This is why we have built with RHE a versatile
          fully encrypted toolbox answering industry imperatives.
        </p>
        <br />
        <p className="text-sm">
          We benchmarked four key functions against major competitors to
          illustrate the magnitude of our quantum-leap breakthroughs. The
          competitors selected for this comparison are fully homomorphic
          libraries that implement the bootstrap in less than one second.
        </p>
        <br />
        <img src={performanceImg} alt="" />
        <br />
        <p className="text-center italic text-sm">
          Computation times for 8-bits, 16-bits, 32-bits and 48-bits integers,
          for a security of 128 bits and two possible decryption error
          probabilities (for RHE and our best competitors). Red crosses indicate
          that calculations take more than one minute. calculations performed on
          a standard laptop (!) with a processor AMD Ryzen 5950X at 4.4GHz with
          parallelization on 16 cores and a RAM 48Go DDR4 300MHz CL16
        </p>
      </div>
    </>
  ),
};

const Technologies = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <motion.div
      className="flex flex-col justify-between h-full bg-bottom bg-no-repeat bg-contain md:bg-right font-inter"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col flex-wrap items-start justify-between h-full px-8 md:mx-auto md:px-16">
        <div className="w-[100%] mt-12 relative z-0">
          <h1 className="text-[40px] sm:text-headline text-white leading-tight font-sans font-light break-words mb-8">
            Ravel Homomorphic <br /> Encryption (RHE)
          </h1>
          {/* Bigger screen view */}
          <div className="relative hidden px-16 mb-8 md:block">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              interval={9000}
              swipeable={false}
              transitionTime={500}
            >
              {contents.map((item, i) => (
                <div
                  className="grid items-center justify-between grid-cols-2 gap-16"
                  key={`Tech-${i}`}
                >
                  <div className="col-span-1 my-8 text-white text-content text-start">
                    <div className="text-3xl">{item.title}</div>
                    <p className="my-6 text-md">{item.content}</p>
                    {item.button ? (
                      <PrimaryButton
                        onClick={() => {
                          setOpen(true);
                        }}
                        type="button"
                        data-modal-toggle="defaultModal"
                      >
                        {item.button}
                      </PrimaryButton>
                    ) : null}
                  </div>
                  <div className="col-span-1 image ">
                    <img src={item.video} alt="video/mp4" />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          {/* Mobile tablet view */}
          <div className="grid items-center justify-between grid-cols-1 gap-16 my-16 md:hidden ">
            {contents.map((item, i) => (
              <div key={`Tech-Mob-${i}`}>
                <div className="grid items-center justify-between grid-cols-1 gap-16">
                  <div className="text-content text-white col-span-1 w-[100%]">
                    <div className="text-[20px] md:text-subHeadline">
                      {item.title}
                    </div>
                    <p className="my-6 text-md">{item.content}</p>
                    {item.button ? (
                      <PrimaryButton
                        onClick={() => {
                          setOpen(true);
                        }}
                        type="button"
                        data-modal-toggle="defaultModal"
                      >
                        {item.button}
                      </PrimaryButton>
                    ) : null}
                  </div>
                  <div className="col-span-1 image">
                    <img
                      src={item.video}
                      alt="video/mp4"
                      className="mx-auto max-w-400px"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="hidden px-8 pb-24 mt-6 mb-20 md:block md:px-16">
        <GoToHomepage />
      </div>

      {open && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed top-0 left-0 w-full h-full transition-opacity bg-gray-500 bg-opacity-75 sm:inset-0"></div>

          <div className="fixed top-0 left-0 z-10 w-full h-full overflow-y-auto sm:h-auto sm:inset-0">
            <div className="h-full min-h-full text-center sm:p-4 sm:justify-center sm:h-auto sm:flex sm:items-end sm:items-center sm:p-0">
              <div className="rounded-md overflow-y-auto relative bg-white text-left overflow-hidden shadow-xl transform transition-all w-full h-full sm:my-8 sm:max-w-lg sm:min-w-[700px] sm:w-full px-4 py-6">
                <div
                  className="flex w-full pr-6"
                  style={{ justifyContent: "flex-end" }}
                >
                  <img
                    src={CloseSVG}
                    onClick={() => setOpen(false)}
                    alt="close"
                    className="text-5xl cursor-pointer text-altGray"
                  />
                </div>
                <div className="flex w-full p-8">
                  {showModalContent.content}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Technologies;
