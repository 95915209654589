import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import bg from "../assets/images/Grid_background.png";

const NotFound = () => {
  return (
    <motion.div
      className="md:bg-right-bottom	 bg-bottom bg-contain	bg-no-repeat h-[90vh] "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className="row flex flex-col justify-center items-center h-full">
        <h1
          className="text-center mb-5"
          style={{ fontSize: "100px", color: "#f33f3f" }}
        >
          Oops!
        </h1>
        <h1 className="mb-4 text-white text-3xl">404 - PAGE NOT FOUND</h1>
        <p className="mb-4 text-white">
          The page you are looking for might have <br /> been removed or had its
          name changed or is not Unavailable.
        </p>
        <Link to="/" className="text-white mt-3 filled-button">
          <span>Back to Homepage</span>
        </Link>
      </div>
    </motion.div>
  );
};

export default NotFound;
