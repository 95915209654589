import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const SideNav = () => {
  const { pathname } = useLocation();

  return (
    <>
      <div
        className="hidden w-full h-screen md:block md:w-auto navbar-items bg-black font-inter sticky top-0 "
        id="navbar-default"
      >
        <ul className="flex flex-col mt-4 md:flex-row  md:mt-0 md:text-sm md:font-medium h-full ">
          <li
            className={`pb-12 border-r border-l border-white  ${
              pathname === "/about" && "bg-altGray"
            } hover:bg-altGray w-[56px]`}
          >
            <NavLink
              to="/about"
              className="block h-full py-2 pb-10 pr-4 ml-auto pl-3 mt-auto text-white text-xl "
            >
              About
            </NavLink>
          </li>
          <li
            className={`pb-12 border-r  border-white  ${
              pathname === "/technologies" && "bg-altGray"
            } w-[56px]`}
          >
            <NavLink
              to="/technologies"
              className="block h-full py-2 pb-10 pr-4 ml-auto pl-3 mt-auto text-white text-xl "
            >
              Technologies
            </NavLink>
          </li>
          <li
            className={`pb-12 border-r  border-white  ${
              pathname === "/usecases" && "bg-altGray"
            } w-[56px]`}
          >
            <NavLink
              to="/usecases"
              className="block h-full py-2 pb-10 pr-4 ml-auto pl-3 mt-auto text-white text-xl"
            >
              Use Cases
            </NavLink>
          </li>
          <li
            className={`pb-12   ${
              pathname === "/team" && "bg-altGray"
            } w-[56px]`}
          >
            <NavLink
              to="/team"
              className="block h-full py-2 pb-10 pr-4 ml-auto pl-3 mt-auto text-white text-xl"
            >
              Team
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideNav;
