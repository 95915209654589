import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Homepage from "../pages/Homepage";
import About from "../pages/About";
import Team from "../pages/Team";
import UseCases from "../pages/UseCases";
import NotFound from "../pages/NotFound";
import Technologies from "../pages/Technologies";
const AnimatedRoutes = ({ pageRef }) => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Homepage pageRef={pageRef} />} />
        <Route exact path="/about" element={<About />} />
        {/* <Route exact path="/contact" element={<Contact />} /> */}
        <Route exact path="/team" element={<Team pageRef={pageRef} />} />
        <Route exact path="/technologies" element={<Technologies />} />
        <Route
          exact
          path="/usecases"
          element={<UseCases pageRef={pageRef} />}
        />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
